import { mapMutations } from 'vuex';
export default {
  props: {
    showForm: { // 显示形式 route 表示路由 popup 弹窗
      type: String,
      default: 'route'
    },
    params: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  computed: {
    route() {
      let route = this.$route
      if (this.showForm === 'popup') {
        route = this.params
      }
      return route
    }
  },
  methods: {
    ...mapMutations('clue', ['CLUE_FILTER']),
    ...mapMutations('cust', ['CUST_FILTER']),
    ...mapMutations('business', ['BUS_FILTER']),
    ...mapMutations('follow', ['FOLLOW_FILTER', 'ACCOMPANY_LIST']),
    ...mapMutations('workreport', ['WORKREPORT_FILTER']),
    ...mapMutations('tree', ['SET_FILTER', 'SET_TIME']),
    storageVuex(orgsales) {
      // 临时添加 筛选
      if (this.route.params && this.route.params.showForm === 'filter') {
        this.SET_FILTER(orgsales)
        this.SET_TIME(new Date().getTime())
        // this.$router.go(-1)
        return
      }
      switch (this.$route.params.routerName) {
        case 'ClueFilter':
          this.CLUE_FILTER(orgsales);
          this.$router.go(-1);
          break;
        case 'cust-filter':
          this.CUST_FILTER(orgsales);
          this.$router.go(-1);
          break;
        case 'business-filter':
          this.BUS_FILTER(orgsales);
          this.$router.go(-1);
          break;
        case 'follow-filter':
          this.FOLLOW_FILTER(orgsales);
          this.$router.go(-1);
          break;
        case 'AddeditFollow':
          this.ACCOMPANY_LIST({ name: orgsales.multiple.name, value: orgsales.multiple.value });
          this.$router.go(-1);
          break;
        case 'workreport-filter':
          this.WORKREPORT_FILTER(orgsales);
          this.$router.go(-1);
          break;
        case 'ClueDetails':
          this.$createDialog(
            {
              type: 'confirm',
              icon: 'cubeic-alert',
              title: '转移',
              content: `是否将线索线索名称转移给${orgsales.transferSales.name}?`,
              confirmBtn: {
                text: '确定',
                active: true,
                disabled: false,
                href: 'javascript:;'
              },
              cancelBtn: {
                text: '取消',
                active: false,
                disabled: false,
                href: 'javascript:;'
              },
              onConfirm: () => {
                this.toTransfer(orgsales);
              },
              onCancel: () => {}
            }).show();
          break;
      }
    }
  }
};
